:root {
  // see https://github.com/fullcalendar/fullcalendar/blob/master/packages/common/src/styles/vars.css
  --fc-event-border-color: transparent;
  --fc-day-height: 190px;
  --bs-body-font-size: 12px;
  --fc-small-font-size: var(--bs-body-font-size);

  @include media-breakpoint-down(xxl) {
    --fc-day-height: 160px;
  }

  @include media-breakpoint-down(md) {
    --fc-day-height: 140px;
  }
}

.fc-daygrid-event {
  padding: 5px;
  margin-right: 2px;
}

.fc-daygrid-more-link {
  font-size: 12px;
  font-weight: 600;
}

.fc-daygrid-day-bottom {
  text-align: center;
}

