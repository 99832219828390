//
// orders.scss
//

.export-list {
  padding: 0;
}

.export-row {
  display: grid;
  grid-template-columns: auto 20px;
}
