.obj-map {
  --obj-map-title-font-size: 20px;
  --obj-map-room-font-size: 19px;
  --obj-map-room-width: 65px;
  --obj-map-room-height: 40px;

  @media screen and (max-device-width: 1600px) {
    --obj-map-title-font-size: 18px;
    --obj-map-room-font-size: 16px;
    --obj-map-room-width: 52px;
    --obj-map-room-height: 32px;
  }

  @media screen and (max-device-width: 1400px) {
    --obj-map-title-font-size: 15px;
    --obj-map-room-font-size: 14px;
    --obj-map-room-width: 39px;
    --obj-map-room-height: 24px;
  }

  font-family: Inter, sans-serif;

  &-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }

  &-title {
    font-size: var(--obj-map-title-font-size);
    font-weight: 600;
  }

  &-table {
    display: inline-block;
    border: 5px solid #2c70d7;
    border-radius: 10px;
    table {
      border-collapse: separate;
      border-spacing: 5px;
      td {
        height: calc(var(--obj-map-room-height) + 2px);
      }
    }
  }

  &-room {
    width: var(--obj-map-room-width);
    height: var(--obj-map-room-height);
    display: flex;
    color: white;
    background: #74788d;
    border-radius: 5px;
    font-size: var(--obj-map-room-font-size);
    font-weight: 500;
    justify-content: center;
    align-items: center;

    &.m--free {
      background: #34c38f;
    }
  }
}
